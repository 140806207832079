import { useState } from 'react';
import "./Navbar.css";
import logo from "../img/Logos/canadian-congress-logo.png";
import logo2 from "../img/Logos/nation-builders-award.png";
import { HashLink as Link } from 'react-router-hash-link';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSpeakersDropdownOpen, setIsSpeakersDropdownOpen] = useState(false);
  const [isAwardeesDropdownOpen, setIsAwardeesDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleSpeakersDropdown = () => {
    setIsSpeakersDropdownOpen(!isSpeakersDropdownOpen);
  };

  const closeSpeakersDropdown = () => {
    setIsSpeakersDropdownOpen(false);
  };

  const toggleAwardeesDropdown = () => {
    setIsAwardeesDropdownOpen(!isAwardeesDropdownOpen);
  };

  const closeAwardeesDropdown = () => {
    setIsAwardeesDropdownOpen(false);
  };

  return (
    <header className="header-section">
      <div className="navbar">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
            <img src={logo2} alt="Logo" />
          </Link>
        </div>
        <div className="nav-menu">
          <nav className="mainmenu">
            <ul className={isMenuOpen ? 'nav-links open' : 'nav-links'}>
              <li><Link to="../" onClick={closeMenu}>Home</Link></li>
              <li><Link to="../#about" onClick={closeMenu}>About</Link></li>
              <li><Link to="../#schedule" onClick={closeMenu}>Schedule</Link></li>
              
              {/* Speakers Dropdown */}
              <li 
                className="dropdown" 
                onMouseEnter={toggleSpeakersDropdown} 
                onMouseLeave={closeSpeakersDropdown}
              >
                <Link to="/speakers" onClick={closeMenu}>Speakers</Link>
                <ul className={isSpeakersDropdownOpen ? 'dropdown-menu open' : 'dropdown-menu'}>
                  <li><Link to="/speakers#speakers2024" onClick={closeMenu}>2024 Speakers</Link></li>
                  <li><Link to="/speakers#speakers2023" onClick={closeMenu}>2023 Speakers</Link></li>
                </ul>
              </li>

              {/* Awardees Dropdown */}
              <li 
                className="dropdown" 
                onMouseEnter={toggleAwardeesDropdown} 
                onMouseLeave={closeAwardeesDropdown}
              >
                <Link to="/awardees" onClick={closeMenu}>Awardees</Link>
                <ul className={isAwardeesDropdownOpen ? 'dropdown-menu open' : 'dropdown-menu'}>
                  <li><Link to="/awardees#awardees2024" onClick={closeMenu}>2024 Awardees</Link></li>
                  <li><Link to="/awardees#awardees2023" onClick={closeMenu}>2023 Awardees</Link></li>
                  <li className="nominate-now"><Link to="https://forms.gle/qambbowpFS7br7oT7" onClick={closeMenu}>Nominate 2025 Now!</Link></li>
                </ul>
              </li>

              <li><Link to="/sponsorship" onClick={closeMenu}>Sponsorship</Link></li>
              <li><Link to="../#contact" onClick={closeMenu}>Contact</Link></li>
            </ul>
          </nav>
          <Link to="https://www.eventbrite.ca/e/4th-annual-canada-leadership-summit-nation-builder-awards-gala-2024-tickets-932942083887" className="primary-btn top-btn" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-ticket" /> Get Tickets Now
          </Link>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
